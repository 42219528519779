import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import StudyDesign from '../../../components/study-design'
import chart1 from '../../../images/mean-baca-ratio-r1.webp'

const metaTags = {
  description: 'This is homepage',
  keywords: 'Homepage',
  title: 'Bone Age to Chronological Age Ratio | SUPPRELIN® LA (histrelin acetate)'
}
const reference = (
  <>
    <p><strong>References: 1.</strong> Silverman LA, Neely EK, Kletter GB, et al. Long-term continuous suppression with once-yearly histrelin subcutaneous implants for the treatment of central precocious puberty: a final report of a phase 3 multicenter trial. <em>J Clin Endocrinol Metab</em>. 2015;100(6):2354-2363. <strong>2.</strong> Eugster EA, Clarke W, Kletter GB, et al. Efficacy and safety of histrelin subdermal implant in children with central precocious puberty: a multicenter trial. <em>J Clin Endocrinol Metab</em>. 2007;92(5):1697-1704.</p>
  </>
)

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true} reference={reference}>
    <Row>
      <Col xs={12}>
        <h1>Sustained decrease in bone-age-to-chronological-age (BA:CA) ratio through 5 years<sup>1</sup></h1>
        <h2>Mean BA:CA ratio decreased throughout the study; changes were statistically significant (<em>P</em>&lt;0.05) in all groups compared to baseline at Months 12, 24, 36, and 48. Baseline bone age was missing for one patient.</h2>
        <ul className='brand-list'>
          <li>
            Treatment with SUPPRELIN<sup>&reg;</sup> LA reduced the mean BA:CA ratio through the 5th year of therapy (n=7)
          </li>
        </ul>
      </Col>
    </Row>
    <Row center="xs">
      <Col xs={12}>
        <img src={chart1} alt="Mean BA CA Ratio 5 years" title="Mean BA CA Ratio 5 years" />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <StudyDesign>
          <p className='study-copy'><strong>STUDY DESIGN:</strong> A long-term extension of a Phase 3, prospective, open-label study evaluating the efficacy and safety of a 50-mg histrelin subcutaneous implant in children with CPP.<sup>2</sup> Thirty-one patients deemed clinically appropriate for continued treatment were eligible to enter the study extension; the previous implant was removed after 12 months and a new implant inserted annually for up to 72 months, until the patient no longer required hormone suppression or the patient discontinued from the study. The primary endpoint was LH suppression as measured in response to GnRHa stimulation. Efficacy assessments also included secondary endpoints that measured the suppression of FSH and gonadal sex steroids (estrogen in girls and testosterone in boys) on treatment. Other assessments were clinical (evidence of stabilization or regression of signs of puberty) or gonadal steroid-dependent (bone age advancement, linear growth).<sup>1</sup></p>
          <p className='study-copy'>
            CPP=central precocious puberty.<br />
            FSH=follicle-stimulating hormone.<br />
            GnRHa=gonadotropin-releasing hormone agonist.<br />
            LH=luteinizing hormone.
          </p>
        </StudyDesign>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/safety-tolerability-profile'}
          LinkCaption="See safety and tolerability profile for SUPPRELIN® LA - bone age to chronological age"
        >
          See safety and tolerability profile for <nobr>SUPPRELIN<sup>&reg;</sup> LA</nobr>

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/caregiver-resources"
          LinkCaption="View videos designed to help your patients understand CPP and SUPPRELIN LA - bone age to chronological age"
        >
          View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
